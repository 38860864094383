<template>
  <div>

    <b-row>
      <b-col  md="12" > 
        <date-range-picker
          ref="picker"
          :locale-data="{ direction: 'ltr',
                          format: 'mm/dd/yyyy',
                          separator: ' - ',
                          applyLabel: 'Valider',
                          cancelLabel: 'Annuler',
                          weekLabel: 'W',
                          customRangeLabel: 'Custom Range',
                          daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                          monthNames: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                          firstDay: 0
                        }"
          v-model="dateRange"
          :ranges="{
            'Today': [today, new Date(today.getFullYear(), today.getMonth(), today.getDate(), 11, 59, 59, 999)],
            'Yesterday': [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1), new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 11, 59, 59, 999)],
            'This month': [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999)],
            'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1),new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999)],
            'This year': [new Date(today.getFullYear(), 0, 1),new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)],
            'Last year': [new Date(today.getFullYear() - 1, 0, 1),new Date(today.getFullYear() - 1, 11, 31, 11, 59, 59, 999)]
          }"
        >
            <template v-slot:input="picker" style="min-width: 350px;">
            <feather-icon icon="CalendarIcon" svgClasses="h-4 w-4" />
                {{ picker.startDate | moment("DD-MMMM-YYYY") }} - {{ picker.endDate | moment("DD-MMMM-YYYY") }}
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />

            </template>

             <!--    ranges (new slot syntax) -->
            <template #ranges="ranges">
              <div class="ranges">
                <ul>
                  <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)">
                    <b v-if="name == 'Today'">Aujourd'hui</b>
                    <b v-if="name == 'Yesterday'"> Hier</b>
                    <b v-if="name == 'This month'"> Ce mois</b>
                    <b v-if="name == 'This year'"> Cette année</b>
                    <b v-if="name == 'Last month'">Mois dérnier</b>
                    <b v-if="name == 'Last year'">Année dérnière</b>
                  </li>
                </ul>
              </div>
            </template>
        </date-range-picker>

      </b-col>
    </b-row>

    <section id="dashboard-analytics">
      <b-row class="match-height">
      
        <b-col lg="3" sm="6">
          <statistic-card-with-area-chart
            icon="CreditCardIcon"
            :statistic="filteredBasic"
            statistic-title="Basic"
          />
        </b-col>

        <b-col lg="3" sm="6">
          <statistic-card-with-area-chart
            icon="CreditCardIcon"
            :statistic="filteredPremium"
            statistic-title="Premium"
          />
        </b-col>

        <b-col lg="3" sm="6">
          <statistic-card-with-area-chart
            icon="CreditCardIcon"
            :statistic="filteredPlatinum"
            statistic-title="Platinum"
          />
        </b-col>

        <b-col
          lg="3"
          sm="6"
        >
          <statistic-card-with-area-chart
            icon="PackageIcon"
            color="warning"
            :statistic="CA"
            statistic-title="CA"
          />
        </b-col>

        

      </b-row>


    </section>

    <b-card title="Balance">
      <div class="d-flex justify-content-between flex-wrap">
        <div class="mb-1 mb-sm-0">
          <span class="text-muted">Commercial networks and enterprises</span>
        </div>
        <div class="d-flex align-content-center mb-1 mb-sm-0">
          <h1 class="font-weight-bolder ">
            $ 183,382
          </h1>
          <div class="pt-25 ml-75">
            <b-badge variant="light-secondary">
              <feather-icon
                icon="ArrowDownIcon"
                size="12"
                class="text-danger align-middle"
              />
              <span class="font-weight-bolder align-middle"> 24%</span>
            </b-badge>
          </div>
        </div>
      </div>

      <!-- echart -->
      <app-echart-line :option-data="option" />

    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BBadge } from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import { moment } from 'vue-moment';

import { mapGetters, mapActions } from "vuex"


export default {
  components: {
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    AppEchartLine,
    BBadge,
    DateRangePicker,
    moment,
  },
  data() {

    var date = new Date();
    let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    let endDate = new Date();

    let today = new Date()
    today.setHours(0, 0, 0, 0)

    return {
      data: {},
      // option: {
      //   xAxisData: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12', '17/12', '18/12', '19/12', '20/12'],
      //   series: ['290', '200', '210', '190', '250', '220', '80', '90', '200', '150', '160', '100', '140', '100', '30'],
      // },

      option: {
        xAxisData: [],
        series: [],
      },

      dateRange: {startDate, endDate},

      today: today
    }
  },


  computed: {

      ...mapGetters('historiqueAbonnements', {'historiqueAbonnements' : 'GET_ITEMS'}),
      ...mapGetters('historiqueRecharges', {'historiqueRecharges' : 'GET_ITEMS'}),

       getDates() {
        const dates = []
        let currentDate = this.dateRange.startDate

        const addDays = function (days) {
          const date = new Date(this.valueOf())
          date.setDate(date.getDate() + days)
          return date
        }
        while (currentDate <= this.dateRange.endDate) {
          dates.push(currentDate)
          currentDate = addDays.call(currentDate, 1)
        }
        return dates
      },

      daysLineCA() {
        var days = ['Dim.','Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'];
        let dates = this.getDates
        let xAxisData = []
        let series = []

        dates.forEach(date => {
          var nbr = this.historiqueAbonnements.filter(item => {
            return item.debut >= this.format_date(date) && item.debut <= this.format_date(date)
          }).reduce((sum, i) =>sum + i.montant, 0)
    
          var shortDate = days[date.getDay()] +' '+ date.getDate() +'/'+ (date.getMonth() + 1)

          nbr = Math.round((nbr + Number.EPSILON) * 100) / 100

          console.log("nbr:",nbr);
          xAxisData.push(shortDate)
          series.push(nbr)
        })

        this.option= {
          xAxisData: xAxisData,
          series: series,
        }
      },

      filteredBasic(){
        return this.historiqueAbonnements.filter(item => {
          return item.debut >= this.format_date(this.dateRange.startDate) && item.debut <= this.format_date(this.dateRange.endDate) && item.type == "BASIC"
        }).length
      },

      filteredPremium(){
        return this.historiqueAbonnements.filter(item => {
          return item.debut >= this.format_date(this.dateRange.startDate) && item.debut <= this.format_date(this.dateRange.endDate) && item.type == "PREMIUM"
        }).length
      },

      filteredPlatinum(){
        return this.historiqueAbonnements.filter(item => {
          return item.debut >= this.format_date(this.dateRange.startDate) && item.debut <= this.format_date(this.dateRange.endDate) && item.type == "PLATINUM"
        }).length
      },

      filteredAbonnementItems(){
        return this.historiqueAbonnements.filter(item => {
          return item.debut >= this.format_date(this.dateRange.startDate) && item.debut <= this.format_date(this.dateRange.endDate)
        })
      },

      filteredRechargeItems(){
        return this.historiqueRecharges.filter(item => {
          return item.date >= this.format_date(this.dateRange.startDate) && item.date <= this.format_date(this.dateRange.endDate)
        })
      },

      CA() {
        let numA =  this.filteredAbonnementItems.reduce((sum, item) =>sum + item.montant, 0)
        let numR =  this.filteredRechargeItems.reduce((sum, item) =>sum + item.montant, 0)
        return (Math.round((numA + Number.EPSILON) * 100) / 100) + (Math.round((numR + Number.EPSILON) * 100) / 100)
      },

     


  },
  methods: {

     ...mapActions('historiqueAbonnements', {'fetchHistoriqueAbonnements' : 'fetchItems'}),
     ...mapActions('historiqueRecharges', {'fetchHistoriqueRecharges' : 'fetchItems'}),

     format_date(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [year, month, day].join('-');
    },

  },

  created() {
    this.fetchHistoriqueAbonnements()
    this.fetchHistoriqueRecharges()

    this.daysLineCA
    console.log("this.fetchHistoriqueAbonnements():",this.fetchHistoriqueAbonnements());
  },
}
</script>

<style>

  .num_object:hover{
    color: #be092f;
    text-shadow: -0.25px -0.25px 0 #be092f, 0.25px 0.25px #be092f;
  }

.vue-daterange-picker{
   float: right !important
}

.slot {
  background-color: #aaa;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}


@media only screen and (min-width: 600px) {
  .daterangepicker.openscenter[data-v-1ebd09d2] {
      right: auto;
      left: 0%;
      -webkit-transform: translate(-73%);
      transform: translate(-73%);
  }
}

[dir] .daterangepicker td.active, [dir] .daterangepicker td.active:hover {
    background-color: rgba(var(--vs-primary), 1);
    border-color: transparent;
}

[dir] .daterangepicker .btn-primary, [dir] .daterangepicker .btn-success {
    background-color: rgba(var(--vs-danger),1);
}

.feather-icon .feather-rotate-cw, .feather-icon .feather-x {
  display: none;
}

[dir=ltr] .vx-card .vx-card__header .vx-card__actions .vx-card__action-buttons .feather-icon {
    margin-left: 5px !important;
}

[dir=ltr] .vs-con-table .vs-table--header, [dir=rtl] .vs-con-table .vs-table--header {
  margin-bottom: 1.5rem;
}

.calendars {
  flex-wrap: nowrap !important;
}

section{
  margin-top: 20px;
}

</style>
